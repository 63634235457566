<template>
  <div class="detail-all">
    <div class="courseGoodsDetailCont">
      <el-row>
        <el-col :span="24">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/opHomepage' }">培训开放平台</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/openPlatform/courseGoods' }">课程列表</el-breadcrumb-item>
            <el-breadcrumb-item>详情</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>

      <div class="detail-comm">
        <div class="goods-poster">
            <img class="course-bg-img" :src="detailObj.picUrl"/>
            <!-- <p class="fix-img" v-show="detailObj.taskType">{{tagName(detailObj.taskType)}}</p> -->
          </div>
          <div class="base-info-comm">
            <div class="name-tag-w">
              <div class="title-video"> 
                <p class="course-name hide">{{detailObj.name}}</p>
                <ul class="video-course">
                    <li class="item" v-show="detailObj.videoNum"><span class="num">{{detailObj.videoNum}}</span><span class="text">视频</span></li> 
                    <li class="bar" v-show="detailObj.videoNum && detailObj.studyHoursNum"></li>
                    <li class="item" v-show="detailObj.studyHoursNum"><span class="num">{{detailObj.studyHoursNum}}</span><span class="text">课时</span></li> 
                  </ul>
              </div>
              <ul class="tag-list" v-if="goodsKind==1">
                <li class="tag-item hide"
                  v-for="(tag, index) in detailObj.labelNameList"
                  :key="index"
                >
                  <!-- <el-tooltip :content="tag" placement="top"> -->
                      <div class="text">{{tag}}</div>
                  <!-- </el-tooltip> -->
                </li>
              
              </ul>
              <div class="detail-lasttime" v-else>培训服务商：{{detailObj.supplierName}}</div>
              <div class="zhouqi"> 
                  <p class="sign-time" v-show="detailObj.signUpStartTime && detailObj.signUpEndTime">
                    <span class="label">报名周期：</span>
                    <span class="time">{{formatDate(detailObj.signUpStartTime)}} - {{formatDate(detailObj.signUpEndTime)}}</span>
                  </p>
                  <p class="blank-bar" v-show="detailObj.signUpStartTime && detailObj.signUpEndTime">1</p>
                  <p class="sign-time peixun" v-if="detailObj.trainingStartTime && detailObj.trainingEndTime">
                    <span class="label">培训周期：</span>
                    <span class="time">{{formatDate(detailObj.trainingStartTime)}} - {{formatDate(detailObj.trainingEndTime)}}</span>
                  </p>
                  <p class="sign-time peixun" v-else-if="detailObj.cycleType === 3">
                    <span class="label">培训周期：</span>
                    <span class="time">长期有效</span>
                  </p>
                  <p class="sign-time peixun" v-else-if="detailObj.cycleType === 2">
                    <span class="label">培训周期：</span>
                    <span class="time">随到随学</span>
                  </p>
                </div>
                <div class="zhiye"> 
                  <p class="sign-time" v-if="detailObj.profession">
                    <span class="label">所属职业：</span>
                    <span class="time">{{detailObj.profession}}</span>
                  </p>
                  <p class="blank-bar" v-show="detailObj.profession && detailObj.workType">1</p>

                  <p class="sign-time" v-if="detailObj.workType">
                    <span class="label">所属工种：</span>
                    <span class="time">{{detailObj.workType}} </span>
                  </p>
                  <p class="blank-bar" v-show="detailObj.skillLevel">1</p>

                  <p class="sign-time" v-if="detailObj.workType && detailObj.skillLevel">
                    <span class="label">技能等级：</span>
                    <span class="time">{{detailObj.skillLevel}}</span>
                  </p>
                </div>
            </div>
            <div class="price-sign-num">
             <div class="price-w">
               <p class="price">
                <span class="label">商品售价：</span>
                <span class="text">{{!detailObj.isFree?'￥'+detailObj.salePrice:'免费'}}</span>
                <span class="or-price" v-if="!detailObj.isFree&&detailObj.basePrice">￥{{detailObj.basePrice}}</span>
               </p>
               <!-- <p class="com-time" v-if="goodsKind==1">
                 有效期：{{time}}
               </p> -->
               <p class="price" v-if="goodsKind==2">
                 <span class="label">预计收益：</span><span class="text color2">￥{{detailObj.preIncome}}</span>
               </p>
             </div>
             <div class="btn-list-w">
               <div class="" v-if="goodsKind==1">
                  <span class="persons" v-if="isJoinPer=='true'">{{detailObj.basicOrderNum?(detailObj.basicOrderNum+detailObj.purchaseNo):detailObj.purchaseNo}}人已报名</span>
                  <el-button :type="isInSignUpCycle?'warning':'info'"  v-if="!detailObj.isOrder" @click="toPay">立即报名</el-button>
                  <el-button type="primary" v-else @click="toStudy">立即学习</el-button>
                </div>
                <div class="" v-if="goodsKind==2">
                  <div class="distributed" v-if="detailObj.isDistribute==1">
                    <span class="dis-text">您已分销，可前往“<i @click="toB()">分销商品</i>”模块管理</span>
                    <el-button type="primary" @click="openDiog()">已分销，去推广</el-button>
                  </div>
                  <el-button type="warning" v-else @click="toDistribute">申请分销</el-button>
                </div>
             </div>
            </div>
          </div>
      </div>
      <div class="detail-content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="简介" name="first">
            <div class="detail" v-html="detailObj.desc">
            </div>
          </el-tab-pane>
          <el-tab-pane label="目录" name="second" v-if="isTab=='true'&&detailObj.goodsType!=3">
            <div class="list" v-if="Object.keys(catalogList).length>0">
              <courseItem  :itemList="catalogList" :isFirst="isFirst" :goodsId="goodsId" :sort="1" :isOverType="isOverType" :detailObj="detailObj"></courseItem>
            </div>
            <div v-else class="noData">
              <img src="../../../assets/images/common/nodata.png" width="242"/>
              <div>课程目录正在建设中，敬请期待～</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog
      title="推广"
      :visible.sync="dialogVisible"
      custom-class="qrcoddialog"
      :close-on-click-modal="false"
      @close="closeIt()"
      >
      <div class="share-box">
        <div>
          <h3>方法一：通过链接</h3>
          <p>分享此链接到微信群和微信好友</p>
          <div class="share-url">{{detailObj.distributionUrl}}</div>
          <div class="share-btn">
            <div class="copyUrl" @click="copyUrl()" data-clipboard-text="12233333">复制链接</div>
          </div>
        </div>
        <div>
          <h3>方法二：通过二维码</h3>
          <div class="code-box">
            <div id="qrcode" ref="qrcode"></div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import QRCode from "qrcodejs2";
import dayjs from 'dayjs'
import courseItem from './courseItem.vue';
export default {
  name: 'courseGoodsDetail',
  components: {
    courseItem
  },
  data () {
    return {
      dialogVisible:false,
          theme: '',
          isLogin:localStorage.getItem('token')?true:false,
          loginBy:localStorage.getItem('loginBy')==1?true:false,
          goodsId:parseInt(this.$route.query.goodsId),
          goodsKind:parseInt(this.$route.query.goodsKind),
          detailObj:{
              basePrice: 0,//划线价格
              days: 0,//购买多少天有效
              desc: "",//简介
              endTime: "",
              goodsType: 1,//商品引用类型1.训练营 2.培训任务
              id: 0,
              isFree: 0,//是否免费
              isOrder: 0,//是否已经购买 0 否 1 是
              name: "",//商品名称
              picUrl: "",
              purchaseNo: 0,//报名人数
              referId: 0,//商品引用ID
              salePrice: 0,//销售价格
              startTime: "",
              status: 1,//状态 0下架 1上架 2 待上架
              taskType: 1,//任务类型 1.必修课 2.选修课 3.公开课
              tryWatch: 1,//是否支持试看
              tryWatchList: [//可试看列表
                {
                  itemId: 0,
                  value: 0
                }
              ],
              validityType: 1//商品有效期类型（1:永久有效、2:期间、3:固定时间、4:购买后期间）
            },
            activeName:'first',
            catalogList:[//目录列表
            ],
            isFirst:true,//是否一级
            isTab:'true',
            isJoinPer:'true',
            isCanBuy:true
    }
  },
  filters: {

  },
  created () {
    this.theme = localStorage.getItem('theme');
    var configurationArr = JSON.parse(localStorage.getItem('configurationArr'));
      var isTab = configurationArr.filter((item)=>{
        return item.key == 'is_show_catalog_tab';
      })
      this.isTab = isTab[0].value;
      var isJoinPer = configurationArr.filter((item)=>{
        return item.key == 'is_show_commodity_number_buyer_detail_page';
      })
      this.isJoinPer = isJoinPer[0].value;
      // console.log(this.isTab)
      // console.log(this.isJoinPer)
  },
  computed: {
    isInSignUpCycle(){
      if(this.detailObj.signUpEndTime){
        if((new Date() - new Date(this.detailObj.signUpEndTime)) > 0 || (new Date() - new Date(this.detailObj.signUpStartTime)) < 0) {

          return false
        } else {
          return true
        }
      }else{
        return true
      }
    },
    //课程类型
    type(){
      if(this.detailObj.taskType==3){
        return '公开课'
      }else if(this.detailObj.taskType==2){
        return '选修课'
      }else{
        return '必修课'
      }
    },
    //是否过期文案显示
    isOver(){
      if(this.detailObj.endTime&&this.detailObj.validityType==4){
        let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
        let nowTime = new Date().getTime();
        if(lastTime>=nowTime){
          return '立即学习'
        }else{
          return '已过期'
        }
      }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
        let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
        let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
        let nowTime = new Date().getTime();
        if(lastTime>=nowTime&&startTime<=nowTime){
          return '立即学习'
        }else{
          if(startTime>=nowTime){
            return '未开始'
          }else{
            return '已过期'
          }
        }
      }else{
        return '立即学习'
      }
    },
    //是否过期
    isOverType(){
      if(this.detailObj.isOrder){
        if(this.detailObj.endTime&&this.detailObj.validityType==4){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime();  
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime){
            return 'oks'
          }else{
            return 'no'
          }
        }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime&&startTime<=nowTime){
            return 'oks'
          }else{
            if(startTime>=nowTime){
              return 'no1'
            }else{
              return 'no2'
            }
          }
        }else if(this.detailObj.validityType==1){
          return 'oks'
        }else{
          return 'ok'
        }
      }else{
        return 'ok'
      }
    },
    //上架状态
    statusText(){
        if(this.detailObj.status==1){
          return '上架'
        }else if(this.detailObj.status==0){
          return '下架'
        }else{
          return '待上架'
        }
    },
    //有效期
      time(){
        if(this.detailObj.validityType==1){
          return "永久有效"
        }else if(this.detailObj.validityType==2){
          let sarr = this.detailObj.startTime.split(' ');
          let stimeArr = sarr[0].split('-');
          stimeArr[0] = stimeArr[0]+'年';
          stimeArr[1] = stimeArr[1]+'月';
          stimeArr[2] = stimeArr[2]+'日';
          let snewTime = stimeArr.join('');
          let earr = this.detailObj.endTime.split(' ');
          let etimeArr = earr[0].split('-');
          etimeArr[0] = etimeArr[0]+'年';
          etimeArr[1] = etimeArr[1]+'月';
          etimeArr[2] = etimeArr[2]+'日';
          let enewTime = etimeArr.join('');
          return snewTime+"-"+enewTime
        }else if(this.detailObj.validityType==3){
          if(this.detailObj.startTime&&this.detailObj.endTime){
            let sarr = this.detailObj.startTime.split(' ');
            let stimeArr = sarr[0].split('-');
            stimeArr[0] = stimeArr[0]+'年';
            stimeArr[1] = stimeArr[1]+'月';
            stimeArr[2] = stimeArr[2]+'日';
            let snewTime = stimeArr.join('');
            let earr = this.detailObj.endTime.split(' ');
            let etimeArr = earr[0].split('-');
            etimeArr[0] = etimeArr[0]+'年';
            etimeArr[1] = etimeArr[1]+'月';
            etimeArr[2] = etimeArr[2]+'日';
            let enewTime = etimeArr.join('');
            return snewTime+"-"+enewTime
          }else{
            let earr = this.detailObj.endTime.split(' ');
            let etimeArr = earr[0].split('-');
            etimeArr[0] = etimeArr[0]+'年';
            etimeArr[1] = etimeArr[1]+'月';
            etimeArr[2] = etimeArr[2]+'日';
            let enewTime = etimeArr.join('');
            return "至"+enewTime
          }
        }else{
          return "报名后"+this.detailObj.days+"天内有效"
        }
      }
  },
  mounted() {
    this.getGoodsDetail();
    // this.getList();
    if (window.history && window.history.pushState) {
            // 往历史记录里面添加一条新的当前页面的url
            history.pushState(null, null, document.URL);
            // 给 popstate 绑定一个方法 监听页面刷新
            window.addEventListener("popstate", this.back, false); //false阻止默认事件
        }
  },

  beforeDestroy() {
    window.removeEventListener("popstate", this.back, false); //false阻止默认事件
  },

  methods: {
    formatDate(date) {
      return dayjs(date).format('YYYY.MM.DD') 
    },
    toB(){
      if(window.location.href.indexOf('testing3') > -1){
        window.open('https://hrss-main.testing3.wdeduc.com/entrance/login')
      }else if(window.location.href.indexOf('testing2') > -1){
        window.open('https://hrss-main.testing2.wdeduc.com/entrance/login')
      }else if(window.location.href.indexOf('testing1') > -1){
        window.open('https://hrss-main.testing1.wdeduc.com/entrance/login')
      }else if(window.location.href.indexOf('fat') > -1){
        window.open('https://hrss-main.fat.wdeduc.com/entrance/login')
      }else if(window.location.href.indexOf('uat') > -1){
        window.open('https://hrss-main.uat.wdeduc.com/entrance/login')
      }else{
        window.open('https://hrss-main.wdeduc.com/saas/distribution/distributor')
      }
    },
    //检查是否可购买商品
    checkUserCanByGoods(){
      this.$api.openPlatform.checkUserCanByGoods(
            {
              goodsId: this.goodsId,
              orderType:this.goodsKind==2?4:1
            }
          ).then(res=>{
            if (res.code===0) {
             this.isCanBuy = res.data;
            }
          })
    },
    //关闭推广弹框
    closeIt(){
      // this.$refs.qrcode = '';
      setTimeout(() => {
        document.getElementById("qrcode").innerHTML = "";
      })
    },
    //推广
    openDiog(){
      this.dialogVisible = true
      this.qrcodeimg();
    },
    //复制链接
    copyUrl(){
      var url = this.detailObj.distributionUrl
      var clipboard = new Clipboard('.copyUrl', {
          text: function () {
              return url
          }
      })
      // 复制成功
      clipboard.on('success', () => {
        this.$message.success('链接已复制')
        // 释放内存
        clipboard.destroy()
      })
      // 复制失败
      clipboard.on('error', () => {
          // 不支持复制
          this.$message.danger('复制失败')
          // 释放内存
          clipboard.destroy()
      })
    },
    //生成二维码
    qrcodeimg () {
       var url = this.detailObj.distributionUrl
        setTimeout(() => {
                new QRCode(this.$refs.qrcode, {
                    text:url,
                    width: 110,
                    height: 110,
                    // colorDark: '#ffffff', // 二维码颜色
                    colorLight: '#ffffff', // 二维码背景色
                    correctLevel: QRCode.CorrectLevel.H // 容错率L/M/H
                }, 100)
            })
    },
    //申请分销
    toDistribute(){
      if(localStorage.getItem('token')){
        if(this.detailObj.isAllow){
            this.$api.openPlatform.getDistribute(
            {
              distributionGoodsId: this.detailObj.id,
            }
          ).then(res=>{
            if (res.code===0) {
              this.$message({
                message: '申请成功',
                type: 'success'
              })
              this.getGoodsDetail()
            }
          })
        }else{
          this.$message({
                message: '很抱歉，暂无权限申请分销',
                type: 'warning'
              })
        }
      }else{
        localStorage.setItem('loginBy',1)
        this.$router.push({
            path: '/login',
            query: {
                name: '/openPlatform/courseGoodsDetail',
                goodsId:this.$route.query.goodsId,
                goodsKind:this.$route.query.goodsKind
            }
        })
      }
    },
    //返回
      back(){
        this.$router.go(-1);
      },
      isToStudy(){
        if(this.detailObj.endTime&&this.detailObj.validityType==4){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime){
            this.toStudy()
          }
        }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime&&startTime<=nowTime){
              this.toStudy()
          }
        }else{
          this.toStudy()
        }
        
      },
    //立即学习
    toStudy(){
      if(this.detailObj.goodsType==1){
        this.$router.push({
          path:'/course/catalog',
          query:{
            taskId:this.detailObj.referId,//训练营id
            taskType:this.detailObj.goodsType,
            goodsId:this.$route.query.goodsId,
            goodsKind:this.$route.query.goodsKind,
            backPath:this.$route.path
          }
        })
      }else if(this.detailObj.goodsType==2){
        // this.$router.push(`/course/catalog?id=${this.detailObj.referId}&dymode=1`)
        this.$router.push({
          path:'/learning/taskDetail',
          query:{
            id:this.detailObj.referId,//培训任务id
            goodsId:this.$route.query.goodsId,
            backPath:this.$route.path,
            goodsKind:this.$route.query.goodsKind,
            type:this.detailObj.goodsType,
          }
        })
      }else if(this.detailObj.goodsType==3){
        this.$router.push({
          path:'/assess',
          query:{
            assessId:this.detailObj.referId,//测评id
            refId:this.$route.query.goodsId,
            refType:2,
            goodsId:this.$route.query.goodsId,
            goodsKind:this.$route.query.goodsKind,
            pathName:this.$route.path
          }
        })
      }
    },
    //获取商品基本信息
    getGoodsDetail(){
      if(this.goodsKind == '2'){
        this.$api.openPlatform.findGoodsDetailById(
          {
            goodsId: this.goodsId,
          }
        )
        .then(res=>{
            // console.log(res);
            if(res.code===0){
              this.detailObj = res.data;
              this.getList();
              if(this.isLogin&&!this.loginBy){
                this.checkUserCanByGoods();
              }
            }
        })
      }else{
        this.$api.openPlatform.findstuGoodsDetailById(
          {
            goodsId: this.goodsId,
          }
        )
        .then(res=>{
            // console.log(res);
            if(res.code===0){
              this.detailObj = res.data;
              this.getList();
              if(localStorage.getItem('token')){
                this.checkUserCanByGoods();
              }
            }
        })
      }
    },
    //目录数据查询
    getList() {
      // console.log(this.detailObj.goodsType)
      if(this.detailObj.goodsType==1){
        //训练营目录
        this.$api.courseGoodsDetail.findTrainListById(
          {
            id: this.detailObj.referId,
          }
        )
        .then(res=>{
            // console.log(res);
            if(res.code===0){
              let tempArr = [];
                let endData = [];
                let timeArr = [];
                let num = [];
                res.data.menuList.forEach(tiem => {
                    tiem.childItemList = [];
                    num.push(tiem.lastStudyTime);
                    if (tiem.lastStudyTime !== null) {
                        timeArr.push(tiem.lastStudyTime);
                    }
                });
                this.timelength = timeArr.sort(function(a, b) {
                    return b > a;
                });
                res.data.menuList.forEach(item => {
                    tempArr.push(item.stageName);
                });
                let setName = [...new Set(tempArr)]; //.sort();
                setName.forEach((el, index) => {
                    let datalist = [];
                    res.data.menuList.forEach(items => {
                        if (el === items.stageName) {
                            items.stageName = items.label
                            datalist.push(items);
                        }
                    });
                    endData.push({
                        stageName: el,
                        id: index,
                        type:0,
                        childItemList: datalist,
                        sort:1
                    });
                });
                this.catalogList = endData;
                // console.log(this.catalogList)
            }else{
              console.log(res.data.message)
            }
        })
      }
      else if(this.detailObj.goodsType==2){
        //培训任务目录
        this.$api.courseGoodsDetail.findTaskListById(
          {
            id: this.detailObj.referId
          }
        )
        .then(res=>{
            // console.log(res);
            if(res.code==0){
              this.catalogList = res.data.taskItemVoList;
            }
        })
      }

    },
    handleClick() {
      // this.dialogVisible=true;
      if(document.getElementsByTagName('video')[0]){
        document.getElementsByTagName('video')[0].pause()
      }
    },
    //立即报名
    toPay(){
        if(this.detailObj.signUpEndTime && this.detailObj.signUpStartTime) {
          if(new Date() - new Date(this.detailObj.signUpEndTime) > 0) {
            this.$message({
              message: '报名已过期',
              type: 'warning'

            })
            return 
          } else if((new Date() - new Date(this.detailObj.signUpStartTime)) < 0){
            this.$message({
              message: '报名未开始',
              type: 'warning'
            })
            return 
          }
        }
        if(localStorage.getItem('token')){
            var obj = {
            goodsId:this.goodsId,
            orderSource:1,
            orderType:this.goodsKind*1 == 2? 4:null //4渠道分销(订单类型)
            }
            //下单
            // if(this.detailObj.isFree){
            
            //   this.$api.courseGoodsDetail.order(obj).then(res=>{
            //       if(res.code==0){
            //       this.$message({
            //           message: '报名成功',
            //           type: 'success'
            //       })
            //       this.detailObj.isOrder = 1;
            //       }
            //   })
            // }else{ // 有价格
            //   this.$api.courseGoodsDetail.order(obj).then(res=>{
            //       if(res.code==0){
            //       if(res.data.placeOrderSuccess) {
            //       this.$router.push(`/openPlatform/orderPage?orderNo=${res.data.orderNo}&dymode=1`)

            //       } else {
            //           if(res.data.url) {
            //           window.open(res.data.url, '_self')
            //           } else {
            //           this.$router.go(-1)
            //           }
            //       }
            //       }
            //   })
            // }
          this.$api.courseGoodsDetail.order(obj).then(res=>{
            if(res.code==0){
              if(res.data.placeOrderSuccess && res.data.realPayPrice  > 0) {
                this.$router.push(`/orderPage?orderNo=${res.data.orderNo}&dymode=1`)

              } else if(res.data.placeOrderSuccess && res.data.realPayPrice  === 0){
                //
                this.$message({
                  message: '报名成功',
                  type: 'success'
                })
                this.detailObj.isOrder = 1;
              } else {
                if(res.data.url) {
                  window.open(res.data.url, '_self')
                } else {
                  this.$router.go(-1)
                }
              }
            }
          })
            
        }else{
            //未登录转登录
            this.$router.push({
                path: '/login',
                query: {
                    name: 'courseGoodsDetail',
                    goodsId: this.goodsId,
                    goodsKind: this.goodsKind //1 普通商品 2 分销商品
                    // type: 'order',
                    // id: this.goodsId,
                    // referId: this.detailObj.referId
                }
            })
        }
    },
  },

};
</script>

<style lang="stylus" scoped>
 @import "../css/courseGoodsDetail.styl"
</style>
