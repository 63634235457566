<template>
  <div class="serviceProviderList">
      <el-row>
        <el-col :span="24">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/opHomepage' }">培训开放平台</el-breadcrumb-item>
            <el-breadcrumb-item>培训服务商列表</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <div class="goodsSearchCont"  ref="searchBtnRef" v-if="loginBy">
        <div class="searchIptBox fr">
          <input type="text" ref="searchInp" class="searchIpt" v-model="goodsKey" v-on:keyup.enter="getData('searchBtn')" placeholder="输入培训服务商名称"/>
          <span class="searchBtn"  @click="getData('searchBtn')">
            <svg-icon icon-class="search"></svg-icon>
          </span>
        </div>
      </div>
      <div class="provider-content">
          <div class="provider-list">
                <div class="provider-item"  v-for="item in providerList" :key="item.id" @click="toList(item)">
                    <div class="left">
                        <img :src="item.coverPicture || 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/openplatform/branchBg.png'" alt="">
                    </div>
                    <div class="right">
                        <div class="name">{{ item.name }}</div>
                        <div class="service">
                            <span>服务类型：</span>
                            <span>{{item.trainOnline?'线上培训服务':''}}{{item.trainOnline&&item.trainOffline?' | ':''}}{{item.trainOffline?'线下实训服务':''}}</span> 
                        </div>
                        <div class="abstract" v-if="item.introduction">
                            <span>简介：</span>
                            <span>{{ item.introduction }}</span>
                        </div>
                        <div class="address">
                            <span>地址：</span>
                            <span>{{item.regionName+item.addressDetail}}</span>
                        </div>
                    </div>
                    <div class="skipBtn">
                        <el-button type="primary" size="small" class="viewDetail" @click="toList(item)">查看详情</el-button>
                    </div>
                </div>
            </div>
      </div>
      <div class="noGoodsTips" v-if="isShowSearchResult && !paging.total">没有搜索到相关培训服务商哟～</div>
      <div class="nothingTips" v-if="!providerList.length&&!isShowSearchResult">还没有培训服务商哟～ </div>
      <paging class="paging pagingCustom" :paging="paging" v-if="paging.total > 0" />
  </div>
</template>

<script>
import paging from "../../../layout/paging";

export default {
  name: 'serviceProviderList',
  components: {
    paging,
  },
  data () {
    return {
      goodsKey:'',
      loginBy:localStorage.getItem('loginBy')==1?true:false,
      isShowSearchResult: false,
      providerList:[
        // {
        //     name:'培训机构1',
        //     id:1,
        //     trainingBaseImage:"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/openplatform/sprovder@2x.png",
        //     addressDetail:'山东省青岛市市南区阳光路12号',
        //     trainOnline:1,
        //     trainOffline:0
        //   },{
        //     name:'培训机构1',
        //     id:2,
        //     trainingBaseImage:"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/openplatform/sprovder@2x.png",
        //     addressDetail:'山东省青岛市市南区阳光路12号',
        //     trainOnline:1,
        //     trainOffline:0
        //   },{
        //     name:'培训机构1',
        //     id:3,
        //     trainingBaseImage:"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/openplatform/sprovder@2x.png",
        //     addressDetail:'山东省青岛市市南区阳光路12号',
        //     trainOnline:1,
        //     trainOffline:0
        //   },{
        //     name:'培训机构1',
        //     id:4,
        //     trainingBaseImage:"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/openplatform/sprovder@2x.png",
        //     addressDetail:'山东省青岛市市南区阳光路12号',
        //     trainOnline:1,
        //     trainOffline:0
        //   },{
        //     name:'培训机构1',
        //     id:5,
        //     trainingBaseImage:"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/openplatform/sprovder@2x.png",
        //     addressDetail:'山东省青岛市市南区阳光路12号',
        //     trainOnline:1,
        //     trainOffline:1
        //   },{
        //     name:'培训机构1',
        //     id:6,
        //     trainingBaseImage:"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/openplatform/sprovder@2x.png",
        //     addressDetail:'山东省青岛市市南区阳光路12号',
        //     trainOnline:1,
        //     trainOffline:1
        //   },
          ],
      // goodsKey:'',
      paging: {
              params: {
                  pageNum: 1,
                  pageSize: 6
              },
              pageSizes: [6,10, 25, 50],
              total: 0,
              totalPages: 0,
              currentSize: 0,
          },
    }
  },
  watch:{
    'goodsKey'(newVal){
        if(!newVal){
          this.paging.params.pageNum = 1
          this.getData()
        }
    },
  },
  filters: {


  },
  created () {
  },
  computed: {

  },
  mounted() {
    this.getData()
  },

  beforeDestroy() {

  },


  methods: {
    toList(item){
      
      this.$router.push({
            path: '/openPlatform/courseGoods',
            query:{
              selectBranchId:item.branchId,
              supplierName:item.name,
              addressDetail:item.addressDetail?item.addressDetail:'',
              trainOffline:item.trainOffline?'线下实训服务':'',
              trainOnline:item.trainOnline?'线上培训服务':'',
              regionName:item.regionName
            }
        }) 
        // 存储机构信息
        let branchDetail = {
            coverPicture:item.coverPicture,
            introduction:item.introduction,
            detail:item.detail,
            branchId: item.branchId,

        }
        sessionStorage.setItem('branchDetail',JSON.stringify(branchDetail))
    },
    getData(parse){
      if (parse == 'searchBtn' && this.$refs.searchBtnRef && !(this.goodsKey.trim()) ) {
        this.$message.error('请输入关键字');
        return false
      }
      if (parse == 'searchBtn') {
        this.paging.params.pageNum = 1
        this.isShowSearchResult = true
      } else {
        this.isShowSearchResult = false
      }
      if(localStorage.getItem('loginBy')==0){
          this.$api.openPlatform.getStuBranches(
              {
                  pageNum: this.paging.params.pageNum,
                  pageSize:  this.paging.params.pageSize,
                  branchName:this.goodsKey?this.goodsKey:""
              }
            )
            .then(res=>{
                if (res.code==0) {
                  this.providerList = res.data.list;
                  this.paging.total = res.data.total; 
                  this.paging.totalPages = res.data.pages
                  this.paging.currentSize = res.data.size
                }
        })
      }else{
        this.$api.openPlatform.getProBranches(
              {
                  pageNum: this.paging.params.pageNum,
                  pageSize:  this.paging.params.pageSize,
                  branchName:this.goodsKey?this.goodsKey:""
              }
            )
            .then(res=>{
                if (res.code==0) {
                  this.providerList = res.data.list;
                  this.paging.total = res.data.total; 
                  this.paging.totalPages = res.data.pages
                  this.paging.currentSize = res.data.size
            }
        })
      }
      
    }
  },

};
</script>

<style lang="stylus" scoped>
 @import "../css/serviceProviderList.styl"
</style>
