import { render, staticRenderFns } from "./orderPage.vue?vue&type=template&id=673fbe2e&scoped=true"
import script from "./orderPage.vue?vue&type=script&lang=js"
export * from "./orderPage.vue?vue&type=script&lang=js"
import style1 from "./orderPage.vue?vue&type=style&index=1&id=673fbe2e&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "673fbe2e",
  null
  
)

export default component.exports