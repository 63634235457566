<template>
  <div class="opHomepage">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="学员" name="0" v-if="!loginBy||!isLogin"></el-tab-pane>
        <el-tab-pane label="培训服务商" name="1" v-if="loginBy||!isLogin"></el-tab-pane>
      </el-tabs>
       <!-- <homeBanner :optype="'student'"/>
       <homeBanner :optype="'serviceProvider'"/> -->
       <div class="bannerImg">
         <img style="width:100%;" v-if="activeName=='0'" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/openplatform/opbanner2@2x (1).png"/>
         <img style="width:100%;" v-else src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/openplatform/opbanner2.png"/>
       </div>
       <div class="opHomepage-content">
          <div class="goodCourse">
            <div class="content-title"><span class="block-name">优质课程</span><span @click="moreList('/openPlatform/courseGoods')" class="more"><span>更多</span><i class="el-icon-arrow-right"></i></span></div>
            <div class="goodCourse-list">
                <div class="course-item" v-for="item in courseList" :key="item.id" @click="toDetail(item)">
                  <img :src="item.picUrl?item.picUrl:item.pic"/>
                  <div class="text-content">
                    <h3 :title="item.name">{{item.name}}</h3>
                    <div class="div-name" :class="loginBy?'isStu':''" :title="item.supplierName?item.supplierName:item.branchName">培训服务商：{{item.supplierName?item.supplierName:item.branchName}}</div>
                    <div class="tag-list-wraper" v-if="!loginBy">
                      <ul class="tag-list">
                        <li class="tag-item tag-active  hide"
                          v-for="(tag, index) in item.labelNameList"
                          :key="index"
                        >
                        <el-tooltip :content="tag" placement="top-start">
                          <div class="text hide">{{tag}}</div>
                        </el-tooltip>

                        </li>
                      </ul>
                      
                    </div>
                    <p>{{item.isFree?'免费':'￥'+item.price}}</p>
                  </div>
                </div> 
            </div>
          </div>
          <div class="provider">
            <div class="content-title" style="margin-top:0;"><span class="block-name">培训服务商</span><span v-if="providerPages > 1" @click="moreList('/openPlatform/serviceProviderList')" class="more"><span>更多</span><i class="el-icon-arrow-right"></i></span></div>
            <div class="provider-list">
                <div class="provider-item"  v-for="item in providerList" :key="item.id" @click="toList(item)">
                    <div class="left">
                        <img :src="item.coverPicture || 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/openplatform/branchBg.png'" alt="">
                    </div>
                    <div class="right">
                        <div class="name">{{ item.name }}</div>
                        <div class="service">
                            <span>服务类型：</span>
                            <span>{{item.trainOnline?'线上培训服务':''}}{{item.trainOnline&&item.trainOffline?' | ':''}}{{item.trainOffline?'线下实训服务':''}}</span> 
                        </div>
                        <div class="abstract" v-if="item.introduction">
                            <span>简介：</span>
                            <span>{{ item.introduction }}</span>
                        </div>
                        <div class="address">
                            <span>地址：</span>
                            <span>{{item.regionName+item.addressDetail}}</span>
                        </div>
                    </div>
                    <div class="skipBtn">
                        <el-button type="primary" size="small" class="viewDetail" @click="toList(item)">查看详情</el-button>
                    </div>
                </div>
                
                <!-- <div class="provider-item" v-for="item in providerList" :key="item.id"  @click="toList(item)">
                  <div class="top-content">
                    <img class="right-img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/openplatform/sprovder@2x.png"/>
                    <div class="left-text">
                      <h3 :title="item.name">{{item.name}}</h3>
                      <p :title="item.methods">培训方式：{{item.trainOnline?'线上培训服务':''}}{{item.trainOnline&&item.trainOffline?' | ':''}}{{item.trainOffline?'线下实训服务':''}}</p>
                    </div>
                  </div>
                  <div class="bottom-address">地址：{{item.regionName+item.addressDetail}}</div>
                </div> -->
            </div>
          </div>
       </div>
  </div>
</template>

<script>
// import homeBanner from '../block/front/homeBanner.vue'
export default {
  name: 'opHomepage',
  components: {
      // homeBanner
  },
  data () {
    return {
          loginBy:localStorage.getItem('loginBy')==1?true:false,
          isAdmin:-1,
          isLogin: localStorage.getItem('token')?true:false,
          activeName:localStorage.getItem('loginBy')==1?'1':'0',
          courseList:[],
          providerList:[],
          providerPages:0//培训服务商页数
    }
  },
  filters: {


  },
  created () {
    this.theme = localStorage.getItem('theme');
  },
  computed: {

  },
  mounted() {
    if(this.isLogin){
      // this.checkAdmin()
      if(localStorage.getItem('loginBy')==0){
        this.getstugoods()
      }else{
          this.getGoodsData()
      }
    }else{
      // let rname = localStorage.getItem('roleName')?localStorage.getItem('roleName'):'student'
      localStorage.setItem('loginBy',parseInt(this.activeName))
      // this.activeName = localStorage.getItem('roleName');
      if(localStorage.getItem('loginBy')==0){
        this.getstugoods()
      } else {
          this.getGoodsData()
      }
    }
  },

  beforeDestroy() {

  },


  methods: {
    //判断是否是管理员登录
    checkAdmin(){
      this.$api.openPlatform.checkAdmin()
          .then(res=>{
              if (res.code==0) {
                this.isAdmin = res.data?1:0;
                let rname = this.loginBy&&res.data?1:0
                localStorage.setItem('loginBy',rname)
                this.activeName = localStorage.getItem('loginBy')+'';
                if(localStorage.getItem('loginBy')==0){
                    this.getstugoods()
                }else{
                    this.getGoodsData()
                }
              }
          })
    },
    //培训服务商
    getGoodsData() {
      this.$api.openPlatform.findByCondition(
            {
                pageNum: 1,
                pageSize:  8,
            }
          )
          .then(res=>{
              if (res.code==0) {
                this.courseList = res.data.list;
              }
          })
          this.$api.openPlatform.getProBranches(
              {
                  pageNum: 1,
                  pageSize:  4,
                  branchName:""
              }
            )
            .then(res=>{
                if (res.code==0) {
                  this.providerList = res.data.list;
                  this.providerPages = res.data.pages || 0
                }
        })
    },
    //学员
    getstugoods(){
      this.$api.openPlatform.findstuByCondition(
            {
                pageNum: 1,
                pageSize:  8,
            },{
            }
          )
          .then(res=>{
              if (res.code==0) {
                this.courseList = res.data.list;
              }
        })
        this.$api.openPlatform.getStuBranches(
              {
                  pageNum: 1,
                  pageSize:  4,
                  branchName:""
              }
            )
            .then(res=>{
                if (res.code==0) {
                  this.providerList = res.data.list;
                }
        })
    },
    moreList(path){
      this.$router.push({
            path: path
        })  
    },
    handleClick(tab){
      localStorage.setItem('loginBy',parseInt(tab.name))
      this.loginBy = localStorage.getItem('loginBy')==1?true:false

      if(tab.name==1){
        this.getGoodsData()
      }else{
        this.getstugoods()
      }
    },
    toDetail(item){
          this.$router.push({
            path: '/openPlatform/courseGoodsDetail',
            query: {
              goodsId:item.goodsKind?item.goodsId:item.id,
              goodsKind:item.goodsKind?item.goodsKind:2
            }
        }) 
    },
    toList(item){
      this.$router.push({
            path: '/openPlatform/courseGoods',
            query:{
              selectBranchId:item.branchId,
              supplierName:item.name,
              addressDetail:item.addressDetail?item.addressDetail:'',
              trainOffline:item.trainOffline?'线下实训服务':'',
              trainOnline:item.trainOnline?'线上培训服务':'',
              regionName:item.regionName
            },
        }) 
        // 存储机构信息
        let branchDetail = {
            coverPicture:item.coverPicture,
            introduction:item.introduction,
            detail:item.detail,
            branchId: item.branchId,
        }
        sessionStorage.setItem('branchDetail',JSON.stringify(branchDetail))
    }
  },

};
</script>

<style lang="stylus" scoped>
 @import "../css/opHomepage.styl"
</style>
