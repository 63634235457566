<template>
    <div class="listin">
        <el-collapse v-model="activeNames">
            <el-collapse-item :class="(isFirst?'firstClass':'')||((detailObj.goodsType==2&&el.type!=1&&el.type!=2&&el.type!=8)||(detailObj.goodsType==1&&el.type!=0&&el.type!=1)?'noAcro':'')" :title="el.stageName?el.stageName:el.contentName" v-for="(el) in itemList" :key="el.id" :name="el.id">
                <template v-if="el.type == 8">
                    <template v-if="el.childItemList && el.childItemList.length>0">
                        <div class="trainAddItem" v-for="(item,index) in el.childItemList" :key="index">
                            <span class="name"> <svg-icon icon-class="address" style="flex-shrink:0;"></svg-icon> <span style="margin-left:16px;">{{item.trainingBaseName}}</span></span>
                            <span class="address">地址：{{item.addressDetail}}</span>
                            <span class="user">联系人：{{item.userName}}</span>
                            <span class="phone">电话：{{item.phone}}</span>
                        </div>
                    </template>
                </template>

                
                <!-- 类似递归形式多级展示 -->
                <div v-if="el.childItemList && el.type!=8" :class="'collapse'+sort">
                    <courseItem  :itemList="el.childItemList" :itemObj="el" :goodsId="goodsId" @loadRespurce="findResourceListById" :sort="sort+1" :isOverType="isOverType" :detailObj="detailObj"></courseItem>
                </div>
                <!-- 课程类型显示数据资源 -->
                    <ul v-if="(detailObj.goodsType!=1&&el.type==2)||(detailObj.goodsType==1&&el.type==1)" class="reList">
                        <li v-for="(ele) in resourceList[el.contentId]" :key="ele.id">
                            <p class="sourse" @click="openVideo(ele)">
                                <span class="typeTip" v-if="ele.type===1">视频</span>
                                <span class="typeTip" v-else-if="ele.type===2">音频</span>
                                <span class="typeTip" v-else-if="ele.type===3">文档</span>
                                <span class="typeTip" v-else-if="ele.type===4">图片</span>
                                <span class="typeTip" style="width:50px" v-else-if="ele.type===6">scorm</span>
                                <span class="typeTip" v-else-if="ele.type===9">试卷</span>
                                <span class="typeTip" v-else-if="ele.type===10">练习</span>
                                <span class="typeTip" v-else-if="ele.type===11">直播</span>
                                <span class="typeTip" v-else-if="ele.type===12">实训</span>

                                <span class="sourse-name" :title="ele.name">{{ele.name}}</span>
                                <span v-if="isOverType!='oks'&&!detailObj.isFree&&ele.tryWatch" class="tryVideo">
                                    <svg-icon icon-class="play"></svg-icon>
                                    <span>可试看</span></span>
                                <span v-else-if="isOverType!='oks'&&(!ele.tryWatch||detailObj.isFree)" class="lock">
                                    <svg-icon icon-class="lock"></svg-icon>
                                </span>
                            </p>
                        </li>
                    </ul>
            </el-collapse-item>
        </el-collapse>
        <el-dialog v-if="isShowD=='ok'"
      :visible.sync="dialogVisible1"
      :close-on-click-modal="false"
      width="1040px"
      top="6vh"
      :before-close="handleClose2">
      <el-dialog
            :visible.sync="innerVisible"
            width="400px"
            top="25vh"
            :close-on-click-modal="false"
            custom-class="tipOver"
            append-to-body>
            <div class="overVideo">
                <svg-icon icon-class="tips"></svg-icon>
                <div class="tryOver">试看结束</div>
                <div v-if="!loginBy" class="tryBottom">购买后可查看完整内容</div>
            </div>
            <div slot="footer" class="dialog-footer" >
                <el-button @click="innerVisible = false">{{loginBy?'确定': '取消'}}</el-button>
                <el-button v-if="!loginBy" type="warning" @click="toPay()">去报名</el-button>
            </div>
        </el-dialog>
                <videoSaas ref="videoSaas2" @openIt="open" :idName="itemObj?itemObj.id:''" :isOrder="detailObj.isOrder"/>
    </el-dialog>
    <el-dialog v-if="isShowD=='ok'"
      :visible.sync="dialogVisible2"
      :close-on-click-modal="false"
      width="1040px"
      top="6vh"
      :before-close="handleClose">
                <div id="Vidpreview"></div>
    </el-dialog>
    <el-dialog v-if="isShowD=='ok'"
      :visible.sync="dialogVisible3"
      :close-on-click-modal="false"
      width="1040px"
      top="6vh"
      :before-close="handleClose">
        <img :src="imgUrl" style="width:100%"/>
    </el-dialog>
    </div>
</template>
<script>
import courseItem from './courseItem.vue';
import videoSaas from './video.vue';
export default {
    name: 'courseItem',
    components: {
        courseItem,
        videoSaas
    },
    props: {
        //当前层级列表
        itemList: {
            type: Array,
            default: () => []
        },
        //是否为训练营
        // isTrain: {
        //     type: Number,
        //     default: 2
        // },
        //是否一级
        isFirst:{
            type: Boolean,
            default: false
        },
        //是否免费
        // isFree:{
        //     type: Number,
        //     default: 0
        // },
        //可试看列表
        // tryWatchList:{
        //     type: Array,
        //     default: () => []
        // },
        //当前层级对象
        itemObj:{
            type: Object,
            default: () => {}
        },
        //商品ID
        goodsId: {
            type: Number,
            default: 1
        },
        //商品引用ID
        // referId: {
        //     type: Number,
        //     default: 1
        // },
        //层级数
        sort:{
            type: Number,
            default: 1
        },
        //是否报名
        // isOrder:{
        //     type: Number,
        //     default: 0
        // },
        //是否过期
        isOverType:{
            type: String,
            default: 'no'
        },
        detailObj:{
            type: Object,
            default: () => {}
        }
    },
     data () {
        return {
            videoUrl:'',
            imgUrl:'',
            activeNames: [0],
            isSelect:'',
            styobj:{
                background:'#ffffff'
            },
            resourceList:{},//课程资源列表
            innerVisible:false,
            dialogVisible1:false,
            dialogVisible2:false,
            dialogVisible3:false,
            loginBy: localStorage.getItem('loginBy')? true: false
        }
     },
     computed:{
         isShowD(){
             var typeList = this.itemList.map(item => {
                 return item.type
             })
             if(this.detailObj.goodsType==1){
                 if(typeList.includes(1)){
                     return 'ok'
                 }else{
                     return 'no'
                 }
             }else if(this.detailObj.goodsType==2){
                 if(typeList.includes(2)){
                     return 'ok'
                 }else{
                     return 'no'
                 }
             }else{
                 return 'no';
             }
         }
     },
     created(){
        // console.log(this.itemObj)
        // this.goodsKind = this.$route.query.goodsKind*1 
        
     },
     mounted() {
        //当this.itemObj.type=2时调用父组件获取课程资源列表findResourceListById方法
        if(this.itemList.length>0){
            this.activeNames = [this.itemList[0].id];
        }
         if((this.detailObj.goodsType==2&&this.itemObj&&this.itemObj.type==2)||(this.detailObj.goodsType==1&&this.itemObj&&this.itemObj.type==1)){
             this.$emit('loadRespurce',this.itemObj.contentId)
         }
        
     },
     methods:{
         //试看结束弹窗
         open(){
             this.innerVisible = true;
         },
         showPreviewPlayer(row){
            // console.log('this.curRow',row)
            var params = ''
            // if(row.transcodingStatus === 2) {
                if (row.type < 3) {
                     this.dialogVisible1=true;
                    //  console.log(row.id)
                    this.$api.openTryWatch.getPlayAuthToken(row).then(res => {
                        if (res.success && res.data) {
                            // 音频
                            if(row.type === 2){
                                params = {
                                    format:'mp3',
                                    mediaType:'audio',
                                    watchPercent:row.watchLength
                                }
                            }else if(row.type === 1){
                                params = {
                                    format:'m3u8',
                                    mediaType:'video',
                                    watchPercent:row.watchLength
                                }
                            }
                            // console.log(params)
                            // 视频，音频安全相关的
                            // this.curVideoUrl = res.data;
                            // this.dialogPreviewVisiblePlayer = true;
                            this.$nextTick(() => {
                                // console.log(this.$refs.videoSaas2)
                                if(res.data?.sourceSing && (res.data?.sourceSing=='222' || res.data.sourceSing=='333')){
                                    // 三方，222 格莱森的，333交通的
                                    this.$refs.videoSaas2.initPlayer(res.data.playUrl,true,{watchPercent: row.tryWatchLength,duration:row.duration})
                                }else{
                                    this.$refs.videoSaas2.initPlayer(res.data,false, params)
                                }
                            })
                            this.dialogVisible1=true;
                        }
                    })
                } else if (row.type === 4) {
                    this.$api.openTryWatch.getImageOrDocUrl(row).then(res => {
                        if (res.data) {
                            this.imgUrl = res.data
                            this.dialogVisible3=true;
                        } 
                    })
                }else if(row.type === 3){
                    // console.log('ccccccccccc')
                    this.$api.openTryWatch.getDocumentAccessToken(row).then(res => {
                        if(res.data){
                            // 下边是安全才有的
                            this.dialogVisible2=true;
                            setTimeout(()=>{
                                // eslint-disable-next-line no-undef
                                let demo = aliyun.config({
                                    mount: document.querySelector('#Vidpreview'),
                                    url: res.data.previewURL //设置文档预览URL地址。
                                }) 
                                // console.log(document.querySelector('#Vidpreview'))
                                // console.log('demo',demo)
                                // //设置AccessToken。
                                demo.setToken({token: res.data.accessToken});
                                // ppt存在动画才有，否则会报错
                                if(row.name.split('.')[1] === 'ppt'){
                                    demo.PPTApplication().ActivePresentation.SlideShowSettings.Run();
                                    demo.PPTApplication().ActivePresentation.Slides.Count;
                                }
                            },100)
                        }
                        
                    })

                } else if(row.type === 6||row.type === 9||row.type === 10||row.type === 11||row.type === 12){
                    // scrom预览
                    // this.$router.push({
                    //     path: '/resources/scrom/myScrom',
                    //     query: {
                    //         resId:row.id,
                    //         version:row.sourceSign,
                    //     },
                    // });
                    this.$message({
                        message: '请点击立即学习进入',
                        type: 'warning'
                    });
                }
            // } else {
            //     this.$message.warning('转码成功方可预览');
            // }
        },
            //不可试看提示
            noTry(){
                if(this.isOverType=='no1'){
                    this.$message({
                        message: '课程未开始！',
                        type: 'warning'
                    });
                }else if(this.isOverType=='no2'){
                    this.$message({
                        message: '课程已过期！',
                        type: 'warning'
                    });
                }else{
                    this.$message({
                        message: '报名后才可以观看！',
                        type: 'warning'
                    });
                }
            },
           
            toPay(){
                if(this.detailObj.signUpEndTime && this.detailObj.signUpStartTime) {
                    if(new Date() - new Date(this.detailObj.signUpEndTime) > 0) {
                        this.$message({
                            message: '报名已过期',
                            type: 'warning'

                        })
                        return 
                        } else if((new Date() - new Date(this.detailObj.signUpStartTime)) < 0){
                        this.$message({
                            message: '报名未开始',
                            type: 'warning'
                        })
                        return 
                    }
                }
                if(localStorage.getItem('token')){
                    var obj = {
                    goodsId:this.goodsId,
                    orderSource:1,
                    orderType:this.$route.query.goodsKind == 2? 4:null //4渠道分销(订单类型)
                    }
                    //下单
                    this.$api.courseGoodsDetail.order(obj).then(res=>{
                        if(res.code==0){
                        if(res.data.placeOrderSuccess && res.data.realPayPrice  > 0) {
                            this.$router.push(`/orderPage?orderNo=${res.data.orderNo}&dymode=1`)

                        } else if(res.data.placeOrderSuccess && res.data.realPayPrice  === 0){
                            //
                            this.$message({
                            message: '报名成功',
                            type: 'success'
                            })
                            this.detailObj.isOrder = 1;
                        } else {
                            if(res.data.url) {
                                window.open(res.data.url, '_self')
                            } else {
                            this.$router.go(-1)
                            }
                        }
                        }
                    })
                    
                }else{
                    //未登录转登录
                    this.$router.push({
                        path: '/login',
                        query: {
                            name: 'courseGoodsDetail',
                            goodsId: this.goodsId,
                            goodsKind: this.$route.query.goodsKind //1 普通商品 2 分销商品
                            // type: 'order',
                            // id: this.goodsId,
                            // referId: this.detailObj.referId
                        }
                    })
                }
            },
            //关闭图片以及文档弹出层
            handleClose(){
            //   this.dialogVisible1=false;
            //   console.log(this.$refs.videoSaas2[0])
            //   this.$refs.videoSaas2[0].dispose();
              this.dialogVisible2=false;
              this.imgUrl = '';
              this.dialogVisible3=false;
            },
            //关闭视频试看弹窗
            handleClose2(){
              this.dialogVisible1=false;
              this.$refs.videoSaas2.dispose();
            },
            //打开试看弹框
            openVideo(data){
                // true 分销商
                this.loginBy = localStorage.getItem('loginBy') === '1'? true: false
                // if(localStorage.getItem('token')){
                     if(this.detailObj.isOrder&&this.isOverType=='oks'&&localStorage.getItem('loginBy'==0)){
                        if(this.detailObj.goodsType==1){
                            this.$router.push({
                            path:'/course/catalog',
                            query:{
                                taskId:this.detailObj.referId,//训练营id
                                taskType:this.detailObj.goodsType,
                                goodsId:this.$route.query.goodsId,
                                goodsKind:this.$route.query.goodsKind,
                                backPath:this.$route.path
                            }
                            })
                        }else if(this.detailObj.goodsType==2){
                            // this.$router.push(`/course/catalog?id=${this.detailObj.referId}&dymode=1`)
                            this.$router.push({
                            path:'/learning/taskDetail',
                            query:{
                                id:this.detailObj.referId,//培训任务id
                                goodsId:this.$route.query.goodsId,
                                backPath:this.$route.path,
                                goodsKind:this.$route.query.goodsKind,
                                type:this.detailObj.goodsType,
                            }
                            })
                        }else if(this.detailObj.goodsType==3){
                            this.$router.push({
                            path:'/assess',
                            query:{
                                assessId:this.detailObj.referId,//测评id
                                refId:this.$route.query.goodsId,
                                refType:2,
                                goodsKind:this.$route.query.goodsKind,
                                goodsId:this.$route.query.goodsId,
                                pathName:this.$route.path
                            }
                            })
                        }
                    }else{
                        if(this.detailObj.tryWatchList){
                            if(!this.detailObj.isFree&&data.tryWatch){
                                this.showPreviewPlayer(data);
                            }else{
                                this.noTry()
                            }
                        }
                    }
                // } else {    // 去登陆
                //     // 
                //     // let loginBy = localStorage.getItem('loginBy')==1?true:false 
                //     // localStorage.setItem('loginBy',1)
                //     this.$router.push({
                //         path: '/login',
                //         query: {
                //             name: '/openPlatform/courseGoodsDetail',
                //             goodsId:this.$route.query.goodsId,
                //             goodsKind:this.$route.query.goodsKind
                //         }
                //     })
                // }
               
                
            },
            //查询课程资源列表信息
            findResourceListById(id) {
                this.$api.courseGoodsDetail.findResourceListById(
                    {
                        id: id,
                    }
                )
                .then(res=>{
                    // console.log(res);
                    if(res.code==0){
                        var arr = res.data;
                        //试看列表

                        // console.log(this.detailObj.tryWatchList)
                        if(this.detailObj.tryWatchList){
                            var newArray = this.detailObj.tryWatchList.map((item) => {
                                return item.itemId;
                            })
                            //匹配试看信息
                            arr.forEach(el => {
                                if(newArray.indexOf(el.id)>-1){
                                    el.watchLength = this.detailObj.tryWatchList.find((v) => {
                                        return v.itemId == el.id;
                                    }).value;
                                    el.tryWatch = 1;
                                }else{
                                    el.tryWatch = 0;
                                }
                            });
                        }
                        
                        this.$set(this.resourceList, id, arr)
                        // console.log(this.resourceList)
                    }else{
                        console.log(res)
                    }
                })
            }
     }
}
</script>
<style lang="stylus" scoped>
 @import "../css/courseItem.styl"

</style>