<template>
    <div class="orderPage">
        <div class="title">确认订单信息</div>
        <div class="order-content">
            <h1 class="titles">订单信息</h1>
            <div class="exam-list">
                <div class="exam">
                    <img :src="orderDetail.picUrl" alt="">
                    <div class="list">
                        <h2 class="name">{{orderDetail.name}}</h2>
                        <p class="time" v-if="$route.query.goodsKind==1">有效期：{{time}}</p>
                        <p class="label">商品金额：<span class="price">¥{{orderDetail.salePrice}}</span><span v-if="orderDetail.basePrice" class="throungh-price">¥{{orderDetail.basePrice}}</span></p>
                    </div>
                </div>
            </div>
            <div class="type" v-if="false">
                <h2>支付方式</h2>
                <div class="payment">
                    <img class="wximg" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/wxzf.png" alt="">
                    <span class="label">微信支付</span>
                    <div class="rightB"></div>
                    <img class="xzimg" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/dg.png" alt="">
                </div>
            </div>
            <div class="pay">
                <span class="paidIn">实付金额：<span class="money">¥{{orderDetail.salePrice}}</span></span>
                <span class="btn" @click="preOrder">立即支付</span>
            </div>
        </div>
        <el-dialog
            class="tip-dialog"
            :visible.sync="paymentdialogVisible"
            width="400px"
            :before-close="closeCode"
            :close-on-click-modal='false'>
            <div class="payment" v-if="type == 'payment'">
                <img class="close" @click="closeCode" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/gb.png" alt="">
                <p class="payment-title">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/weixin.png" alt="">
                    <span class="label">微信支付</span>
                    <span class="icon">¥<span class="num">{{orderDetail.salePrice}}</span></span>
                </p>
                <div class="scan">
                    <div id="qrCode" ref="qrCodeDiv"></div>
                    <div class="left"></div>
                    <div class="right"></div>
                </div>
                <p class="tip">使用微信扫描二维码进行支付</p>
            </div>
            <div class="success" v-if="false">
                <img :src="type == 'success'? img1 : img2 " class="icon-img" alt="">
                <p class="titles">{{type == 'success'? '支付成功' : '支付失败'}}</p>
                <p class="text">{{type == 'success'? '支付成功！你可以点击下方按钮，进行学习。' : '支付失败，请你检查微信账户余额、网络状态，确认后重新支付。'}}</p>
                <el-button class="btn" @click="jumpStudy">{{type == 'success'? '立即学习' : '重新支付'}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: 'orderPage',
  show: true,
    data() {
        return {
            orderDetail: {},
            orderList: {},
            paymentdialogVisible: false,
            type: '',
            img1: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/zfcg.png',
            img2: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/zfsb.png',
            Countdown: null,
            qrcodeState: ''
        };
    },
    filters: {
      dataFormat(msg) {
        if (msg) {
            const m = (msg||'').split(' ')[0].split('-');
            const s = m.join('.');
            return s;
        }
      }
    },
    mounted() {
        this.getOrderDetail()
        // this.placeOrder();
    },
    computed:{
//有效期
      time(){
        if(this.orderDetail.validityType==1){
          return "永久有效"
        }else if(this.orderDetail.validityType==2){
          let sarr = this.orderDetail.startTime.split(' ');
          let stimeArr = sarr[0].split('-');
          stimeArr[0] = stimeArr[0]+'年';
          stimeArr[1] = stimeArr[1]+'月';
          stimeArr[2] = stimeArr[2]+'日';
          let snewTime = stimeArr.join('');
          let earr = this.orderDetail.endTime.split(' ');
          let etimeArr = earr[0].split('-');
          etimeArr[0] = etimeArr[0]+'年';
          etimeArr[1] = etimeArr[1]+'月';
          etimeArr[2] = etimeArr[2]+'日';
          let enewTime = etimeArr.join('');
          return snewTime+"-"+enewTime
        }else if(this.orderDetail.validityType==3){
          if(this.orderDetail.startTime&&this.orderDetail.endTime){
            let sarr = this.orderDetail.startTime.split(' ');
            let stimeArr = sarr[0].split('-');
            stimeArr[0] = stimeArr[0]+'年';
            stimeArr[1] = stimeArr[1]+'月';
            stimeArr[2] = stimeArr[2]+'日';
            let snewTime = stimeArr.join('');
            let earr = this.orderDetail.endTime.split(' ');
            let etimeArr = earr[0].split('-');
            etimeArr[0] = etimeArr[0]+'年';
            etimeArr[1] = etimeArr[1]+'月';
            etimeArr[2] = etimeArr[2]+'日';
            let enewTime = etimeArr.join('');
            return snewTime+"-"+enewTime
          }else{
            let earr = this.orderDetail.endTime.split(' ');
            let etimeArr = earr[0].split('-');
            etimeArr[0] = etimeArr[0]+'年';
            etimeArr[1] = etimeArr[1]+'月';
            etimeArr[2] = etimeArr[2]+'日';
            let enewTime = etimeArr.join('');
            return "至"+enewTime
          }
        }else{
          return "报名后"+this.orderDetail.days+"天内有效"
        }
      }
    },
    created() {
        this.paymentdialogVisible = JSON.parse(sessionStorage.getItem("dialogState")) || this.paymentdialogVisible;
        this.type = sessionStorage.getItem("typeState") || this.type;
        this.qrcodeState = sessionStorage.getItem("qrcodeState")?JSON.parse(sessionStorage.getItem("qrcodeState")) : ''
        if (this.qrcodeState) {
            this.qrcodeimg(this.qrcodeState);
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalId)
    },
    methods: {
        jumpStudy () {
            if (this.type == 'success') {
                // sessionStorage.setItem('dy-mode', '1');
                // 清楚缓存弹框状态
                sessionStorage.removeItem("dialogState")
                // 清楚缓存支付状态
                sessionStorage.removeItem("typeState");
                // 清楚缓存二维码
                sessionStorage.removeItem("qrcodeState");
                this.$router.replace(`/course/catalog?id=${this.orderDetail.referId}&dymode=1`)
            } else {
                this.preOrder()
            }
        },
        async getOrderDetail() {
            let orderNo = this.$route.query.orderNo
            let res = await this.$api.orderPage.getOrderByNo(orderNo)
            this.orderList = res.data
            this.orderDetail = res.data.goodsList[0]
            this.getOrderStatus()
            this.intervalId = setInterval(() => {
                this.getOrderStatus()
            }, 3000)
        },
           //  查询订单状态
        async getOrderStatus() {

            let orderNo = this.$route.query.orderNo
            let res = await this.$api.orderPage.getOrderStatus(orderNo)
            if(res.data.orderStatus !== 0 ) {
                //
                clearInterval(this.intervalId)
                if(res.data.url) {
                    window.open(res.data.url, '_self')
                } else {
                    this.$router.replace({
                        path: '/courseGoods'
                    })
                }
            }

        },
       // 现在立即支付跳转到统一收银台
        preOrder() {
            this.$api.home.prePayOrde({
                data:{
                    orderId:this.orderList.id,
                    orderNo:this.orderList.orderNo,
                    totalMoney:this.orderDetail.salePrice,
                    payInfoDesc:this.orderDetail.name,
                    scene: 3
                }
            }).then(res=>{
                if (res.success) {
                    this.$api.home.launchPay({
                        data:{
                            orderNo:this.orderList.orderNo,
                            scene: 3
                        }
                    }).then(res=>{
                        console.log('res', res)

                        if (res.data) {
                            // pay.testing1.wdeduc.com
                            // pay.testing3.wdeduc.com
                            // pay.fat.wdeduc.com
                            // pay.uat.wdeduc.com
                            // pay.wdeduc.com

                            let hostname = window.location.hostname
                            let port = window.location.port;
                            let isIP=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
                            // let arr =  hostname.split('.');
                            // let isDTYProEnv = !hostname.split('.').splice(3).join('.'); //线上的定制化主题
                            //let API = arr[0].includes('hrss') ?  `https://pay.${arr.splice(1).join('.')}` : `https://pay.${isDTYProEnv ? arr.splice(1).join('.') : arr.splice(2).join('.')}`

                            let API  = ''
                            let locationHref = window.location.href
                            if (locationHref.includes('testing')) {
                                // API = 'http://pay.testing3.wdeduc.com'
                                API = 'https://fwyz-test.wdeduc.com'
                            } else if (locationHref.includes('fat')) {
                                API = 'https://pay.fat.wdeduc.com'
                            } else if (locationHref.includes('uat')) {
                                API = 'https://pay.uat.wdeduc.com'
                            } else  {
                                API = 'https://pay.wdeduc.com'
                            }

                            if(isIP.test(hostname) || hostname.includes('dev') || !!port){
                                API = 'http://localhost:8081';
                            }
                            const backUrl = encodeURIComponent(window.location.protocol+'//'+window.location.hostname)
                            window.open(API  + "/pcPay?orderNo=" + this.orderList.orderNo + '&token=' + localStorage.getItem('token') + '&organ=' + localStorage.getItem('organ') + '&actor=' + localStorage.getItem('actor') + '&branch=' + localStorage.getItem('branch') + '&referId=' + this.orderDetail.referId + '&theme=gongxin' + '&backUrl=' + backUrl);

                        } else {
                            this.$message.error(res.data.message);
                        }
                    }).catch(err=>{
                        console.log('err', err)
                    })
                }
            })
        },
        // 生成二维码
        qrcodeimg (data) {
            this.paymentdialogVisible = true;
            // 缓存弹框状态
            sessionStorage.setItem("dialogState", this.paymentdialogVisible);
            this.type = 'payment';
            // 缓存支付状态
            sessionStorage.setItem("typeState", this.type);
            setTimeout(() => {
                new QRCode(this.$refs.qrCodeDiv, {
                    text: data.codeUrl,
                    width: 150,
                    height: 150,
                    colorDark: '#333333', // 二维码颜色
                    colorLight: '#ffffff', // 二维码背景色
                    correctLevel: QRCode.CorrectLevel.H // 容错率L/M/H
                }, 100)
            })
            this.findAndSetPayStatus(data.payNo)
        },
        findAndSetPayStatus(data) {
            this.Countdown = null
            const payNos = data || this.qrcodeState.payNo
            this.Countdown = setInterval(() => {
                this.$api.home.findAndSetPayStatus(payNos).then(res=>{
                    if (res.data==true) {
                        clearInterval(this.Countdown)
                        this.type = 'success'
                        sessionStorage.setItem("typeState", this.type);
                        sessionStorage.removeItem("qrcodeState");
                    } else if (res.code != 0) {
                        clearInterval(this.Countdown)
                        this.type = 'fail'
                        sessionStorage.setItem("typeState", this.type);
                        sessionStorage.removeItem("qrcodeState");
                    }
                })
            }, 3000)
        },
        // 关闭弹框,清除已经生成的二维码
        closeCode () {
            this.paymentdialogVisible = false
            sessionStorage.setItem("dialogState", this.paymentdialogVisible);
            this.$refs.qrCodeDiv.innerHTML = ''
            clearInterval(this.Countdown)
            sessionStorage.removeItem("dialogState")
            sessionStorage.removeItem("typeState");
            sessionStorage.removeItem("qrcodeState");
        }
    }
};
</script>
<style lang="stylus">
</style>
<style lang="stylus" scoped>
@import "../css/orderPage.styl"
</style>
